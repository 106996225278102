import { DefaultTheme } from "styled-components";

declare module "styled-components" {
  export interface DefaultTheme {
    primaryColor: string;
    secondaryColor: string;
    primaryButtonColor: string;
    primaryButtonTextColor: string;
    lightButtonColor: string;
    lightPrimaryColor: string;
    textColor: string;
    darkTextColor: string;
    textPrimaryColor: string;
    darkButtonColor: string;
    disabledInputColor: string;
    backgroudColor: string;
    authSideBackground: string;
    sidebarBackground: string;
    containerBackground: string;
    sectionBackground: string;
    sectionBackgroundAlt: string;
    lightBorderColor: string;
    lightTextColor: string;
    whiteTextColor: string;
    sidebarText: string;
    sidebarHeadTextColor: string;
    sidebarActiveMenuBackground: string;
    boxShadow: string;
    labelColor: string;
    statsHighlightBackground: string;
    heroBackgroundGradient: string;
    landingPageSectionGradient: string
    warningBackground: string
    warningColor: string
  }
}

export const lightTheme: DefaultTheme = {
	primaryColor: "#6F2A6A",
	secondaryColor: "#F16108",
	primaryButtonColor: "#6F2A6A",
  primaryButtonTextColor: "#fff",
  lightButtonColor: "rgb(250, 250, 250)",
  lightPrimaryColor: "rgb(248, 224, 255)",
  textColor: "rgb(94, 94, 94)",
  darkTextColor: "#222",
	textPrimaryColor: "#6F2A6A",
  darkButtonColor: "#222",
  lightTextColor: "#505d5b",
  whiteTextColor: "#fff",
  disabledInputColor: "rgb(229, 177, 243)",
  backgroudColor: "rgb(247, 248, 252)",
  authSideBackground: "#6F2A6A",
  sidebarBackground: "#6F2A6A",
  sidebarText: "#fff",
  sidebarHeadTextColor: "#fff",
  sidebarActiveMenuBackground: "rgba(65, 23, 62, 0.95)",
  containerBackground: "#fff",
  sectionBackground: "#fff",
  sectionBackgroundAlt: "#f9f9f9",
  lightBorderColor: "#eee",
  boxShadow: "rgba(230, 230, 230)",
  labelColor: "#676767",
  statsHighlightBackground: "#6F2A6A",
  heroBackgroundGradient: "linear-gradient(1deg, rgba(138, 39, 131, 0.97) 12.72%, #2A1027 70.42%, #1E1E1E 99.99%, #7C557A 100%)",
  landingPageSectionGradient: "linear-gradient(90deg, rgba(41,17,39,1) 0%, rgba(102,59,98,1) 100%)",
  warningBackground: "rgb(255, 243, 208)",
  warningColor: "rgb(169, 127, 8)"
};

export const darkTheme: DefaultTheme = {
	primaryColor: "#6F2A6A",
	secondaryColor: "#F16108",
	primaryButtonColor: "rgb(96, 55, 93)",
  primaryButtonTextColor: "#fff",
  lightButtonColor: "#21262d",
  lightPrimaryColor: "rgb(45, 37, 48)",
  textColor: "#e6edf3",
  darkTextColor: "#f7f7f7",
	textPrimaryColor: "rgb(242, 159, 235)",
  lightTextColor: "#999",
  whiteTextColor: "#222",
  darkButtonColor: "#eee",
  disabledInputColor: "rgb(229, 177, 243)",
  backgroudColor: "#0d1117",
  authSideBackground: "#0d1117",
  sidebarBackground: "rgb(22, 27, 34)",
  sidebarText: "#fff",
  sidebarHeadTextColor: "#fff",
  sidebarActiveMenuBackground: "rgba(41, 41, 41, 0.95)",
  containerBackground: "#161b22",
  sectionBackground: "#020202",
  sectionBackgroundAlt: "#161b22",
  lightBorderColor: "rgb(44, 44, 44)",
  boxShadow: "rgb(5, 5, 5)",
  labelColor: "#999",
  statsHighlightBackground: "rgb(92, 90, 171)",
  heroBackgroundGradient: "linear-gradient(1deg, rgba(0, 0, 0, 0.97) 12.72%, rgb(0, 0, 0) 70.42%, rgb(30, 30, 30) 99.99%, rgb(0, 0, 0) 100%)",
  landingPageSectionGradient: "linear-gradient(90deg, rgb(13, 13, 13) 0%, rgb(41, 41, 41) 100%)",
  warningBackground: "rgb(62, 59, 51)",
  warningColor: "rgb(245, 182, 0)"
};