import dayjs from "dayjs";
import { get12HourFormat } from "../../../../../utils/time/time";

export const defaultChartState = {
    labels: [],
    datasets: [
        {
          label: '',
          fill: true,
          lineTension: 0.5,
          backgroundColor: '#222',
          borderColor: '#222',
          borderWidth: 3,
          data: [],
          yAxisID: 'y',
        },
    ]
  }

  export const options = {
    responsive: true,
    maintainAspectRatio: false,
    showLine: true,
    plugins: {
      legend: {
        display: true,
      },
      title: {
        display: false,
      },
      filler: {
        propagate: true
      },
    },

    scales: {
        y: {
            type: 'linear' as const,
            display: true,
            position: 'left' as const,
            grid: {
                display: false
            }
        },
        x: {
            display: true,
            grid: {
                display: false
            }
        },
      },
  };

export const resolveDataOutStats = (total_data_out: any, appId: string, server_hour: string) => {
    const _rawTotalSentList = total_data_out
    const _refignedTotalSentList: {date: Date, size: number, hour: number}[] = []

    for (let index = 0; index < _rawTotalSentList.length; index++) {
        const _rawTotalSent = _rawTotalSentList[index];

        Object.keys(_rawTotalSent).forEach(function(key) {
            const _rawDate = key.replace(appId + "_bytes_sent_", "")
            // console.log(key, _rawTotalSent[key], " _rawDate: ", _rawDate);
            const dateArr = _rawDate.split("/")
            const _realDate = new Date(dateArr[0] + " " + dateArr[1] + ":00")
            _refignedTotalSentList.push({
                size: parseFloat((_rawTotalSent[key] / 1000000).toFixed(2)),
                date: _realDate,
                hour: parseInt(dateArr[1])
            })
        });
        
    }
    const _dataset: any[] = []

    const _pre_date_sort = [..._refignedTotalSentList]
    const _pre_size_sort = [..._refignedTotalSentList]

    const _sortedDate = _pre_date_sort.sort((a, b) =>  a.date.getTime() - b.date.getTime());
    const _sortedSize = _pre_size_sort.sort((a, b) =>  a.size - b.size);
    

    // console.log('====================================');
    // console.log("_refignedTotalSentList: ", _refignedTotalSentList);
    // console.log('====================================');

    // console.log('====================================');
    // console.log("omo _sortedDate: ", _sortedDate);
    // console.log('====================================');

    // console.log('====================================');
    // console.log("_sortedSize: ", _sortedSize);
    // console.log('====================================');

    const _max = _sortedSize[_sortedSize.length - 1]
    const _min = _sortedSize[0]
    
    let _reduceIdx = 24
    for (let index = 0; index < 24; index++) {
        const date = server_hour
        const _raw_key = parseInt(date) - index
        let _key = 0
        let _value = 0
        if (_raw_key > 0) {
            _key = _raw_key
        } else {
            _key =  _reduceIdx
            --_reduceIdx
        }

        const _filterdItem = _sortedDate.filter((item) => item.hour == _key)
        if (_filterdItem.length) {
            _value = _filterdItem[0].size
        }
        _dataset.push({
            [get12HourFormat(_key)]: _value
        }) 
    }

    const reversed_dataset = _dataset.reverse()

    const _label: string[] = []
    const _data: any[] = []

    for (let index = 0; index < reversed_dataset.length; index++) {
        const element = reversed_dataset[index];
        Object.keys(element).forEach(function(key) {
            _label.push(key)
            _data.push(element[key])
        });
    }

    const _bytes_sent_state = {
        labels: _label as never[],
        datasets: [
            {
              label: 'Data Sent',
              fill: true,
              lineTension: 0.5,
              backgroundColor: '#00991f22',
              borderColor: '#00991f66',
              borderWidth: 2,
              data: _data as never,
              yAxisID: 'y',
            },
        ]
    };

    const _result = _sortedDate.reduce((accumulator, currentValue) => {
        return accumulator + currentValue.size;
      }, 0)

    return {
        state: _bytes_sent_state,
        max: _max.size,
        min: _min.size,
        last: _data[_data.length -1],
        avg: parseFloat((_result / 12).toFixed(2))
    }
}

export const resolveDataInStats = (total_data_out: any, appId: string, server_hour: string) => {
    const _rawTotalSentList = total_data_out
    const _refignedTotalSentList: {date: Date, size: number, hour: number}[] = []

    for (let index = 0; index < _rawTotalSentList.length; index++) {
        const _rawTotalSent = _rawTotalSentList[index];

        Object.keys(_rawTotalSent).forEach(function(key) {
            const _rawDate = key.replace(appId + "_bytes_received_", "")
            // console.log(key, _rawTotalSent[key], " _rawDate: ", _rawDate);
            const dateArr = _rawDate.split("/")
            const _realDate = new Date(dateArr[0] + " " + dateArr[1] + ":00")
            _refignedTotalSentList.push({
                size: parseFloat((_rawTotalSent[key] / 1000000).toFixed(2)),
                date: _realDate,
                hour: parseInt(dateArr[1])
            })
        });
        
    }
    const _dataset: any[] = []

    const _pre_date_sort = [..._refignedTotalSentList]
    const _pre_size_sort = [..._refignedTotalSentList]

    const _sortedDate = _pre_date_sort.sort((a, b) =>  a.date.getTime() - b.date.getTime());
    const _sortedSize = _pre_size_sort.sort((a, b) =>  a.size - b.size);

    const _max = _sortedSize[_sortedSize.length - 1]
    const _min = _sortedSize[0]
    
    let _reduceIdx = 24
    for (let index = 0; index < 24; index++) {
        const date = server_hour
        const _raw_key = parseInt(date) - index
        let _key = 0
        let _value = 0
        if (_raw_key > 0) {
            _key = _raw_key
        } else {
            _key =  _reduceIdx
            --_reduceIdx
        }

        const _filterdItem = _sortedDate.filter((item) => item.hour == _key)
        if (_filterdItem.length) {
            _value = _filterdItem[0].size
        }
        _dataset.push({
            [get12HourFormat(_key)]: _value
        }) 
    }

    const reversed_dataset = _dataset.reverse()

    const _label: string[] = []
    const _data: any[] = []

    for (let index = 0; index < reversed_dataset.length; index++) {
        const element = reversed_dataset[index];
        Object.keys(element).forEach(function(key) {
            _label.push(key)
            _data.push(element[key])
        });
    }

    const _bytes_received_state = {
        labels: _label as never[],
        datasets: [
            {
              label: 'Data Received',
              fill: true,
              lineTension: 0.5,
              backgroundColor: '#99120022',
              borderColor: '#99120066',
              borderWidth: 2,
              data: _data as never,
              yAxisID: 'y',
            },
        ]
    };

    const _result = _sortedDate.reduce((accumulator, currentValue) => {
        return accumulator + currentValue.size;
      }, 0)

    return {
        state: {..._bytes_received_state},
        max: _max.size,
        min: _min.size,
        last: _data[_data.length -1],
        avg: parseFloat((_result / 12).toFixed(2))
    }
}


export const resolveRequestInStats = (total_data_out: any, appId: string, server_hour: string) => {
    const _rawTotalSentList = total_data_out
    const _refignedTotalSentList: {date: Date, size: number, hour: number}[] = []

    for (let index = 0; index < _rawTotalSentList.length; index++) {
        const _rawTotalSent = _rawTotalSentList[index];

        Object.keys(_rawTotalSent).forEach(function(key) {
            const _rawDate = key.replace(appId + "_requests_received_", "")
            // console.log(key, _rawTotalSent[key], " _rawDate: ", _rawDate);
            const dateArr = _rawDate.split("/")
            const _realDate = new Date(dateArr[0] + " " + dateArr[1] + ":00")
            _refignedTotalSentList.push({
                size: _rawTotalSent[key],
                date: _realDate,
                hour: parseInt(dateArr[1])
            })
        });
        
    }
    const _dataset: any[] = []

    const _pre_date_sort = [..._refignedTotalSentList]
    const _pre_size_sort = [..._refignedTotalSentList]

    const _sortedDate = _pre_date_sort.sort((a, b) =>  a.date.getTime() - b.date.getTime());
    const _sortedSize = _pre_size_sort.sort((a, b) =>  a.size - b.size);

    const _max = _sortedSize[_sortedSize.length - 1]
    const _min = _sortedSize[0]
    
    let _reduceIdx = 24
    for (let index = 0; index < 24; index++) {
        const date = server_hour
        const _raw_key = parseInt(date) - index
        let _key = 0
        let _value = 0
        if (_raw_key > 0) {
            _key = _raw_key
        } else {
            _key =  _reduceIdx
            --_reduceIdx
        }

        const _filterdItem = _sortedDate.filter((item) => item.hour == _key)
        if (_filterdItem.length) {
            _value = _filterdItem[0].size
        }
        _dataset.push({
            [get12HourFormat(_key)]: _value
        }) 
    }

    const reversed_dataset = _dataset.reverse()

    const _label: string[] = []
    const _data: any[] = []

    for (let index = 0; index < reversed_dataset.length; index++) {
        const element = reversed_dataset[index];
        Object.keys(element).forEach(function(key) {
            _label.push(key)
            _data.push(element[key])
        });
    }

    const requests_received_state = {
        labels: _label as never[],
        datasets: [
            {
              label: 'Requests Received',
              fill: true,
              lineTension: 0.5,
              backgroundColor: '#99009922',
              borderColor: '#99009966',
              borderWidth: 2,
              data: _data as never,
              yAxisID: 'y',
            },
        ]
    };

    const _result = _sortedDate.reduce((accumulator, currentValue) => {
        return accumulator + parseInt(currentValue.size.toString())
      }, 0)

    return {
        state: {...requests_received_state},
        max: _max.size,
        min: _min.size,
        last: _data[_data.length -1],
        avg: parseFloat((_result / 12).toFixed(2))
    }
}