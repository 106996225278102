import react, { useEffect, useRef, useState } from 'react'
import { BigWrapper, Container, NavWrapper, Wrapper } from './styles'
import { TbSearch } from 'react-icons/tb'
import { HiOutlineShoppingCart } from 'react-icons/hi'
import { VscMenu } from 'react-icons/vsc'
import { Link,  } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks'
import { setNavigation } from '../../../slices/navigationSlice'
import { ProfileSliceInterface } from '../../../slices/profileSlice'

const Header: React.FC = () => {
    const [floatingClass, setFloatingClass] = useState("")
    const dispatch = useAppDispatch();
    const toggleState = useAppSelector(state => state.navigation.state);

    const [currentLogo, setCorrentLogo] = useState("logo.png")

    const sidebarRef = useRef(null)

    const closeMenu = (e: { target: any }) => {
        if (e.target === sidebarRef.current) {
            dispatch(setNavigation({main: false}));
        }
    }

    const addFloatClass = () => {
        window.onscroll = function() {
			if (window.pageYOffset > 90) {
                setFloatingClass("floating-body")
                setCorrentLogo("logo-alt.png")
			} else {
                setFloatingClass("")

                if (window.innerWidth > 990) {
                    setCorrentLogo("logo.png")
                } else {
                    setCorrentLogo("logo-alt.png")
                }
			}
		}
    }

    useEffect(() => {
        addFloatClass()
    }, [])

    return (
        <BigWrapper>
            <Wrapper className={floatingClass}>
                <Container>
                    <Link className='site-logo' to="/">
                        <img src={"/assets/img/" + currentLogo}  alt="" />
                        <span>Coralscale</span>
                    </Link>
                    <NavWrapper ref={sidebarRef} onClick={e => closeMenu(e)} navigationState={toggleState.main}>
                        <div className="nav-wrapper">
                            <div className="nav-logo">
                                <Link className='site-logo' to="/">
                                    <img src={"/assets/img/" + currentLogo} alt="" />
                                    <span>Coralscale</span>
                                </Link>
                            </div>
                            <nav>
                                <ul>
                                    <li><Link  onClick={ () => dispatch(setNavigation({main: false})) } to="/pricing">Pricing</Link></li>
                                    <li><Link  onClick={ () => dispatch(setNavigation({main: false})) } to={'/contact-us'}>Contact Us</Link></li>
                                    <li><Link  onClick={ () => dispatch(setNavigation({main: false})) } to={'#contact'}>About Us</Link></li>
                                </ul>
                            </nav>
                            <div className="auth-nav">
                                <ul>
                                    <li><Link  onClick={ () => dispatch(setNavigation({main: false})) } to="/signin">Sign In</Link></li>
                                    <li><Link  onClick={ () => dispatch(setNavigation({main: false})) } to="/signup">Sign Up</Link></li>
                                </ul>
                            </div>
                        </div>
                    </NavWrapper>
                    <div className="toggle-menu" onClick={ () => dispatch(setNavigation({main: true})) }>
                        <VscMenu />
                    </div>
                </Container>
            </Wrapper>
        </BigWrapper>
    )
}

export default Header