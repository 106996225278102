import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hooks";
import { usePaystackPayment } from "react-paystack";
import Message from "../../../components/message/Message";
import AxiosCall from "../../../../utils/axios";
import { InitializePayment } from "react-paystack/dist/types";
import { updateCreatAppState } from "../../../../slices/createAppSlice";
import { updateWalletState } from "../../../../slices/walletSlice";
import { fetchProfileAction } from "../../../../actions/profileAction";

const usePaymentModel = () => {
    const creatApp = useAppSelector(state => state.createApp.state);
    const [initData, setInitData] = useState<any>(null)
    const [isPaymentSuccess, setIsPaymentSuccess] = useState(false)
    const [isInitTransaction, setIsInitTransaction] = useState(false)
    const [isVerifyingTransaction, setIsVerifyingTransaction] = useState(false)
    const dispatch = useAppDispatch()

    const handlePaystackPaymet = (initializePayment: InitializePayment) => {
        const onSuccess = (reference: string) => {
            setInitData(null)
            dispatch(updateCreatAppState({...creatApp, addSiteModal: true, showPaymentMethodModal: false}))
        };
      
        const onClose = () => {
          console.log('closed')
          dispatch(updateCreatAppState({...creatApp, showPaystackModal: false}))
        }

        initializePayment({onSuccess, onClose})
    }

    const updatePaymentStatus = (value: boolean) => {
        setIsPaymentSuccess(value);
    }

    const getPaymentReference = async ({paymentMethod, planId} : {paymentMethod: string, planId?: string}) => {
        try {
            setIsInitTransaction(true)
            
            const res: any = await AxiosCall({
                method: "POST",
                path: "/v1/transaction/init?trans_type=pebble_payment",
                data: {
                    payment_method: paymentMethod,
                    plan_id: planId,
                }
            });

            // console.log("response:",res);
            if (res.status == 1) {
                setIsInitTransaction(false)

                setInitData(res.data)
            } else {
                setIsInitTransaction(false)
                Message.error(res.message)
            }
        } catch (err: any) {
            setIsInitTransaction(false)
            Message.error(err?.response.data.message)
        }
    }

    return {
        isInitTransaction,
        handlePaystackPaymet,
        getPaymentReference,
        initData,
        isPaymentSuccess,
        updatePaymentStatus,
        isVerifyingTransaction
    }
}

export default usePaymentModel