export const app_subscriptions = [
    {
        planId: 1,
        name: "Starter",
        description: "Suitable for testing",
        price: 0,
        perks: [
            "Automatic CI/CD (Git Integration)",
            "Support for multiple stacks",
            "Community support",
            "Analytics",
            "1 custom domain (includes free SSL)",
            "Market place access",
            "1 volume per pebble"
        ]
    },
    {
        planId: 2,
        name: "Basic (popular)",
        description: "Suitable for production applications",
        price: 20,
        perks: [
            "Includes all starter features",
            "1 core",
            "1GB RAM",
            "Dedicated build pipline (Faster builds)",
            "Weekly backups",
            "Script Scheduler",
            "Customer support",
            "Unlimited custom domain(includes free SSL)",
            "Multiple volumes per pebble",
        ]
    },
    {
        planId: 3,
        name: "Pro",
        description: "Suitable for production applications",
        price: 32,
        perks: [
            "Includes all starter features",
            "2 cores",
            "2GB RAM",
            "Dedicated build pipline (Faster builds)",
            "Weekly backups",
            "Script Scheduler",
            "Customer support",
            "Unlimited custom domain(includes free SSL)",
            "Multiple volumes per pebble",
        ]
    }
]