import { AddSiteModal, Container, DeploySiteModal, DeploymentModal, EmptyContent, SiteInfoWrapper, SiteItem, SitesWrapper, StatsItem, StatsWrapper, SubscriptionCard, SubscriptionModal, Wrapper } from './styles'
import { TfiStatsUp } from 'react-icons/tfi'
import { MdAddCircleOutline } from 'react-icons/md'
import { AiFillGithub, AiOutlineRight, AiOutlineSetting } from 'react-icons/ai'
import { BiGitBranch } from 'react-icons/bi'
import { HiArrowRight, HiOutlineCloudArrowDown } from 'react-icons/hi2'
import { useEffect, useRef, useState } from 'react'
import PopupModal from '../../components/popupModal/PopupModal'
import useQuery from '../../../hooks/useQuery'
import Message from '../../components/message/Message'
import AxiosCall from '../../../utils/axios'
import Loader from '../../components/Loader/Loader'
import Button from '../../components/button/Button'
import { getApps } from '../../../actions/appActions'
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks'
import { AppSliceInterface } from '../../../slices/appSlice'
import { IoMdApps, IoMdCheckmark } from 'react-icons/io'
import { FiArrowRight } from 'react-icons/fi'
import { app_subscriptions } from '../../../data'
import PaymentMethod from './payment_method/PaymentMethod'
import { updateCreatAppState } from '../../../slices/createAppSlice'
import ComplexDropdown from '../../components/complexDropdown/ComplexDropdown'
import { Project } from '../../../slices/projectSlice'

const DashboardScreen: React.FC = () => {
    const creatApp = useAppSelector(state => state.createApp.state);
    const projectSlice = useAppSelector(state => state.projects.state);
    const teamSlice = useAppSelector(state => state.teams.state);
    const appSlice: AppSliceInterface  = useAppSelector(state => state.apps.state);
    const [deploySiteModal, setDeploySiteModal] = useState(false)
    const [isFetchingAccessToken, setIsFetchingAccessToken] = useState(false)
    const [showBranchList, setShowBranchList] = useState(false)
    const [showRepoList, setShowRepoList] = useState(false)
    const [showOrgList, setShowOrgList] = useState(false)
    const [isFetchingRepo, setIsFetchingRepo] = useState(false)
    const [isFetchingOrgs, setIsFetchingOrgs] = useState(false)
    const [repoList, setRepoList] = useState<any[]>([])
    const [orgList, setOrgList] = useState([])
    const [repoBranchList, setRepoBranchList] = useState([])
    const [showDeploymentModal, setShowDeploymentModal] = useState(false)
    const [githubCode, setGithubCode] = useState<string | null>()
    const [selectedRepoOwner, setSelectedRepoOwner] = useState<string | null>()
    const [deployRepo, setDeployRepo] = useState<string | null>(null)
    const [deployBranch, setDeployBranch] = useState<string | null>(null)
    const [githubUser, setGithubUser] = useState<string>("")
    const [searchRepoInput, setSearchRepoInput] = useState("")

    const [selectedProject, setSelectedProject] = useState<Project | null>(null)

    const planButtonRefs = useRef<HTMLButtonElement[]>([])
    const planContinueTextRefs = useRef<HTMLSpanElement[]>([])
    const planArrowTextRefs = useRef<any[]>([])

    const dispatch = useAppDispatch()

    const query = useQuery()

    let loftycode: string | null;
    let install_id: string | null;
    const getGithubCode = () => {
        const code = query.get('code')
        loftycode = code
        install_id = query.get('installation_id')
        setGithubCode(code)

        if (code != null) {
            getGithubAccessToken()
        }
    }

    const deploySite = (e: any) => {
        e.preventDefault()

        window.location.assign("https://github.com/apps/coralscale/installations/new")
    }

    const getGithubAccessToken = async () => {
        try {
            setIsFetchingAccessToken(true)

            const created_app = localStorage.getItem("new_app")
            const res: any = await AxiosCall({
                method: "GET",
                path: "/v1/app/deploy/github/accesstoken?code="+loftycode+"&installation_id="+install_id+"&app_slug="+created_app+"&team_id=" + teamSlice.defaultTeam!.id
            });

            console.log("response:",res);
            if (res.status == 1) {
                setIsFetchingAccessToken(false)

                setShowDeploymentModal(true)
                setIsFetchingOrgs(true)
                localStorage.setItem("github_user", res.data.github_user)
                setGithubUser(res.data.github_user)
                setTimeout(() => {
                    getGithubOrgs()
                }, 2000);
            } else {
                setIsFetchingAccessToken(false)
                Message.error(res.message)
            }
        } catch (err: any) {
            setIsFetchingAccessToken(false)
            Message.error(err?.response.data.message)
        }
    }

    const getGithubOrgs = async () => {
        try {
            setShowDeploymentModal(true)
            setIsFetchingOrgs(true)

            const created_app = localStorage.getItem("new_app")
            const github_user = localStorage.getItem("github_user")

            const res: any = await AxiosCall({
                method: "POST",
                path: "/v1/app/github/orgs/fetch",
                data: {
                    appSlug: created_app,
                    githubUsername: github_user
                }
            });

            console.log("response:",res);
            if (res.status == 1) {
                setIsFetchingOrgs(false)
                setShowOrgList(true)
                setOrgList(res.data.organizations)
            } else {
                setIsFetchingOrgs(false)
                Message.error(res.message)
            }
        } catch (err: any) {
            setIsFetchingOrgs(false)
            Message.error(err?.response.data.message)
        }
    }



    const getGithubRepo = async ({username} : {username?: string | null}) => {
        try {
            setShowDeploymentModal(true)
            setIsFetchingRepo(true)

            const created_app = localStorage.getItem("new_app")
            const github_user = username == null ? localStorage.getItem("github_user") : username

            setIsFetchingOrgRepo(github_user)

            const res: any = await AxiosCall({
                method: "POST",
                path: "/v1/app/github/repo/fetch",
                data: {
                    appSlug: created_app,
                    githubUsername: github_user
                }
            });

            console.log("response:",res);
            if (res.status == 1) {
                setSelectedRepoOwner(username)
                setShowOrgList(false)
                setIsFetchingRepo(false)
                setShowRepoList(true)
                setRepoList(res.data.repos)

            } else {
                setIsFetchingRepo(false)
                Message.error(res.message)
            }
        } catch (err: any) {
            setIsFetchingRepo(false)
            Message.error(err?.response.data.message)
        }
    }

    const [fetchingRepoBranch, setIsFetchingRepoBranch] = useState<string | null>(null);
    const [fetchingOrgRepo, setIsFetchingOrgRepo] = useState<string | null>(null);

    const getGithubRepoBranch = async (repoName: string) => {
        try {
            if (fetchingRepoBranch != null) {
                return;
            }
            const created_app = localStorage.getItem("new_app")
            setIsFetchingRepoBranch(repoName);
            const github_user = localStorage.getItem("github_user")

            const res: any = await AxiosCall({
                method: "POST",
                path: "/v1/app/github/repo/branches/fetch?appSlug="+created_app,
                data: {
                    ownerName: selectedRepoOwner,
                    repoName: repoName
                }
            });

            console.log("response:",res);
            if (res.status == 1) {
                setDeployBranch(null)
                setShowRepoList(false)
                setShowBranchList(true)
                setRepoBranchList(res.data.branches)
                setIsFetchingRepoBranch(null)
                setDeployRepo(repoName)

            } else {
                setIsFetchingRepoBranch(null)
                Message.error(res.message)
            }
        } catch (err: any) {
            setIsFetchingRepoBranch(null)
            Message.error(err?.response.data.message)
        }
    }

    useEffect(() => {
        if (teamSlice.defaultTeam!.id) {
            getGithubCode()
        }
    }, [teamSlice.defaultTeam!.id])

    const [isAddingSite, setIsAddingSite] = useState(false)
    const appNameRef = useRef<HTMLInputElement>(null)
    const appDescriptionRef = useRef<HTMLInputElement>(null)
    const appRootPathRef = useRef<HTMLInputElement>(null)
    const appStackRef = useRef<HTMLSelectElement>(null)

    const addSite = async () => {
        try {

            if (appStackRef!.current!.value == "none") {
                return Message.error("Please select stack")  
            }

            if (!appNameRef.current?.value.length) {
              return Message.error("Site name can't be empty")  
            }
            setIsAddingSite(true)

            const res: any = await AxiosCall({
                method: "POST",
                path: "/v1/app/new?team_id=" + teamSlice.defaultTeam?.id + "&project_id=" + selectedProject?.id,
                data: {
                    appName: appNameRef?.current?.value,
                    description: appDescriptionRef?.current?.value,
                    rootPath: appRootPathRef?.current?.value,
                    stack: appStackRef?.current?.value,
                    plan_id: creatApp.selectedPlanId,
                    reference: creatApp.paymentReference,
                    payment_method: creatApp.selectedPaymentMethod
                }
            });

            if (res.status == 1) {
                setIsAddingSite(false)
                dispatch(updateCreatAppState({...creatApp, addSiteModal: false}))
                setDeploySiteModal(true)
                localStorage.setItem("new_app", res.data.slug)
                getApps(dispatch)
                Message.success("App created successfully")
            } else {
                setIsAddingSite(false)
                Message.error(res.message)
            }
        } catch (err: any) {
            setIsAddingSite(false)
            Message.error(err?.response?.data?.message ?? "An error occurred")
        }
    }

    const [isActivatingBranch, setIsActivatingBranch] = useState(false)
    const activateDeployBranch = async (e: any) => {
        try {
            e.preventDefault();

            if (isActivatingBranch) {
                return;
            }

            const created_app = localStorage.getItem("new_app")
            const github_user = localStorage.getItem("github_user")
            setIsActivatingBranch(true)
            
            const res: any = await AxiosCall({
                method: "POST",
                path: "/v1/app/github/deploy/activate?appSlug="+created_app,
                data: {
                    repoName: deployRepo,
                    branchName: deployBranch,
                    githubUsername: selectedRepoOwner,
                    repoOwner: selectedRepoOwner
                }
            });

            if (res.status == 1) {
                setIsActivatingBranch(false)
                setShowDeploymentModal(false)
                Message.success("Deploy activated successully")
                triggerDeploy()
            } else {
                setIsActivatingBranch(false)
                Message.error(res.message)
            }
        } catch (err: any) {
            setIsActivatingBranch(false)
            Message.error(err?.response?.data?.message ?? "An error occurred")
        }
    }

    const triggerDeploy = async () => {
        try {
            const created_app = localStorage.getItem("new_app")
            
            const res: any = await AxiosCall({
                method: "POST",
                path: "/v1/app/github/deploy/trigger?appSlug="+created_app
            });

        } catch (err: any) {
            
        }
    }


    useEffect(() => {
        if (!appSlice.fetchedSites) {
            getApps(dispatch)
        }
    }, [])

    const checkForStripeTransaction = () => {
        const payment_id = query.get('stripe_pay_id')
        const plan_id = query.get('plan_id')
        console.log("payment_id: ", payment_id);
        
        if (payment_id && plan_id) {
             setTimeout(() => {
                dispatch(updateCreatAppState({...creatApp, paymentReference: payment_id, selectedPlanId: plan_id, addSiteModal: true, selectedPaymentMethod: "stripe"}))
             }, 1000);
        }
    }

    const searchRepo = () => {
        const _repoList = repoList.filter((item: any) => item.name.toLowerCase().includes(searchRepoInput.toLowerCase()))
        setRepoList(_repoList)
    }

    useEffect(() => {
        checkForStripeTransaction()
    }, [])

    const checkForActions = () => {
        const action = query.get('coral-action')
        
        if (action == "create-app") {
             setTimeout(() => {
                window.history.replaceState( {} , 'coralscale', '/dashboard' );
                dispatch(updateCreatAppState({...creatApp, showSubScriptionModal: true}))
             }, 1000);
        }
    }

    useEffect(() => {
        checkForActions()
    }, [])

    useEffect(() => {
        searchRepo()
    }, [searchRepoInput])

    return (
        <Wrapper>
            <Container>
                <StatsWrapper>
                    <StatsItem>
                        <div className="icon-box">
                            <TfiStatsUp />
                        </div>
                        <span>No. Pebbles</span>
                        <span>{appSlice.apps.length}</span>
                    </StatsItem>
                    <StatsItem>
                        <div className="icon-box">
                            <TfiStatsUp />
                        </div>
                        <span>Requests</span>
                        <span>_</span>
                    </StatsItem>
                    <StatsItem>
                        <div className="icon-box">
                            <TfiStatsUp />
                        </div>
                        <span>Builds</span>
                        <span>_</span>
                    </StatsItem>
                    <StatsItem>
                        <div className="icon-box">
                            <TfiStatsUp />
                        </div>
                        <span>Domains</span>
                        <span>0</span>
                    </StatsItem>
                </StatsWrapper>

                <SiteInfoWrapper>
                    <div className="head">
                        <h5>Recent Pebbles</h5>
                        
                        <button className="add-site" onClick={() => dispatch(updateCreatAppState({...creatApp, showSubScriptionModal: true}))}>
                            <MdAddCircleOutline />
                            <span>add pebble</span>
                        </button>
                    </div>
                
                    <SitesWrapper>
                        <div className="site-list">
                            {appSlice?.isLoading ? <Loader styleTwo center topPadding="40px" bottomPadding="40px" /> : !appSlice.apps.length ? <EmptyContent>
                                    <div className="text-content">
                                        <IoMdApps />
                                        <span>You've not added any pebble</span>
                                        <p>A pebble is a stand alone server which is used to host a frontend or backend product that you've build</p>
                                    </div>
                                    <button onClick={() => dispatch(updateCreatAppState({...creatApp, showSubScriptionModal: true}))}>add app</button>
                                </EmptyContent> : appSlice.apps.map((item: any, idx) => {
                                    return <SiteItem key={"apps-"+idx} state={item} to={"/dashboard/pebbles/"+item.slug}>
                                    <div className="img-wrapper">
                                        <img src={"/assets/img/colored/"+item?.stack+".png"} alt="" />
                                    </div>
                                    <div className="col">
                                        <h4>{item.name}</h4>
                                        <span>{item.stack}</span>
                                    </div>
                                    <AiOutlineRight />
                                </SiteItem>
                            })}
                        </div>
                    </SitesWrapper>
                </SiteInfoWrapper>

                <PopupModal showPopup={creatApp.addSiteModal} closeModal={() => dispatch(updateCreatAppState({...creatApp, addSiteModal: false})) }>
                    <AddSiteModal>
                        <h4>Add a new app</h4>
                        
                        <ComplexDropdown isLoading={projectSlice.isLoading} placeHolder="-- selected project --" options={projectSlice.projects!} optionValue={"name"} showSearch={true} onChange={(item) => {
                            setSelectedProject(item)
                        }}  />

                        <input ref={appNameRef} type="text" placeholder='Enter site name' />
                        <input ref={appDescriptionRef}  type="text" placeholder='Enter description' />
                        {/* <input ref={appRootPathRef}  type="text" placeholder='Enter root path (default is the base directory)' /> */}
                        <select ref={appStackRef} name="application-type" id="">
                            <option value="none"> -- select stack -- </option>
                            <option value="reactjs">React js</option>
                            <option value="vuejs">Vue js</option>
                            <option value="nextjs">Next js</option>
                            <option value="nodejs">Node js</option>
                            <option value="golang">Go (Golang)</option>
                            <option value="flutter_web">Flutter Web</option>
                            <option value="dart_server">Dart Server (dart frog)</option>
                            <option value="vanilla">HTML CSS and JS</option>
                            <option value="laravel">Laravel</option>
                            <option value="php">PHP</option>
                            <option value="sparkle">Sparkle</option>
                        </select>
                        <Button isDisabled={selectedProject == null || !teamSlice.defaultTeam?.id} onClick={() => addSite()} isLoading={isAddingSite}>CREATE</Button>
                    </AddSiteModal>
                </PopupModal>

                <PopupModal showPopup={deploySiteModal} closeModal={() => setDeploySiteModal(false)}>
                    <DeploySiteModal>
                        <h4>Select deployment mode</h4>

                        <button onClick={deploySite}>Deploy from github</button>
                    </DeploySiteModal>
                </PopupModal>

                <PopupModal showPopup={isFetchingAccessToken}>
                    <Loader center styleTwo />
                </PopupModal>

                <PopupModal showPopup={showDeploymentModal} closeModal={() => setShowDeploymentModal(false)}>
                    <DeploymentModal>
                        <h5>{showOrgList ? "Where do you want to deploy from" : "Select deploy repo and branch"}</h5>
                        {isFetchingOrgs ? <Loader styleTwo center topPadding="20px" /> : <></>}
                        {showOrgList && <ul className="repo-list">
                            <li className={""} key={"repo-"+"-1"} onClick={() => {getGithubRepo({username: githubUser})}}> <div className="icon-box"><AiFillGithub /></div> <span>{githubUser}</span>{fetchingOrgRepo == githubUser ? <Loader styleTwo /> : <HiArrowRight />}</li>
                            {orgList.map((item: any, index) => {
                                return <li key={"org-"+index} onClick={() => getGithubRepo({username: item.name})} ><img src={item.imge_url} alt='image icon'/><span>{item.name}</span>{fetchingOrgRepo == item.name ? <Loader styleTwo /> : <HiArrowRight />}</li>
                            })}
                        </ul>}
                        {showRepoList && <ul className="repo-list">
                            <div className="input-wrapper">
                                <input type="text" placeholder="search .." onChange={(e) => setSearchRepoInput(e.target.value)} />
                            </div>
                            {repoList.map((item: any, index) => {
                                return <li key={"repo-"+index} onClick={() => getGithubRepoBranch(item.name)} ><img src={item.imge_url} alt='image icon'/><span>{item.name}</span>{fetchingRepoBranch == item.name ? <Loader styleTwo /> : <HiArrowRight />}</li>
                            })}
                        </ul>}
                        {showBranchList && <ul className="branch-list">
                            <li className="selcted-repo"><HiOutlineCloudArrowDown /><span>{deployRepo}</span><AiOutlineSetting /></li>
                            {repoBranchList.map((item: any, idx) => {
                                return <li className={deployBranch == item.name ? "selected-branch" : ""} key={"branch-"+idx} onClick={() => {setDeployBranch(item.name)}}><BiGitBranch /><span>{item.name}</span><HiArrowRight /></li>
                            })}
                        </ul>}
                        {deployBranch && <button className="deploy-btn" onClick={activateDeployBranch}>{isActivatingBranch ? <Loader /> : "Deploy site"}</button>}
                    </DeploymentModal>
                </PopupModal>

                <PopupModal maxWidth="1080px" background='#f7f7f7' showPopup={creatApp.showSubScriptionModal} closeModal={() => {dispatch(updateCreatAppState({...creatApp, showSubScriptionModal: false}))}}>
                    <h4>Select a plan</h4>
                    <SubscriptionModal>
                        {app_subscriptions.map((item, idx: number) => {
                            return <SubscriptionCard isSelected={creatApp.selectedPlan?.planId == item.planId} key={idx} onClick={(e) => {
                                if (e.target != planButtonRefs.current[idx] && e.target != planContinueTextRefs.current[idx] && e.target != planArrowTextRefs.current[idx]) {
                                    dispatch(updateCreatAppState({...creatApp, selectedPlan: item, selectedPlanId: item.planId}))
                                }
                            } }>
                                <h3>{item.name}</h3>
                                <span className="price">${item.price}</span>
                                <p className="notice">{item.description}</p>
                                <ul>
                                    {item.perks.map((perks, _idx) => {
                                        return <li key={"perk" + _idx}>
                                            <span className="check-box"><IoMdCheckmark /></span>
                                            <span className="title">{perks}</span>
                                        </li>
                                    })}
                                </ul>

                                <button ref={(ref) => planButtonRefs.current.push(ref!)} onClick={() => {
                                    if (creatApp.selectedPlan?.planId != item.planId) return;

                                    if (item.planId > 1) {
                                        dispatch(updateCreatAppState({...creatApp, showSubScriptionModal: false, showPaymentMethodModal: true}))
                                    } else {
                                        dispatch(updateCreatAppState({...creatApp, showSubScriptionModal: false, addSiteModal: true}))
                                    }
                                }}>
                                    <span ref={(ref) => planContinueTextRefs.current.push(ref!)}>Continue</span>
                                    <FiArrowRight />
                                </button>
                            </SubscriptionCard>
                        })}
                    </SubscriptionModal>
                </PopupModal>

                <PaymentMethod />
            </Container>
        </Wrapper>
    )
}

export default DashboardScreen